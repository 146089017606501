<template>
  <div class="c-app">
    <TheSidebarAdmin/>
    <CWrapper>
      <TheHeaderAdmin/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <!--<TheFooter/>-->
    </CWrapper>
  </div>
</template>

<script>
  import TheSidebarAdmin from './TheSidebarAdmin'
  import TheHeaderAdmin from './TheHeaderAdmin'
  //import TheFooter from './TheFooter'

  export default {
    name: 'TheContainerAdmin',
    components: {
      TheSidebarAdmin,
      TheHeaderAdmin,
      //TheFooter
    },
    beforeMount() {
      /*let cookieUsername = this.$cookie.get('userLogginToken')
      if(cookieUsername === null || cookieUsername === ""){
        this.$router.push("/vapp/loginAdmin");
      }*/


    }
  }
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
