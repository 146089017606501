<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img src="/img/logoResp.png" class="c-sidebar-brand-full"/><!--:height="40"-->
      <img src="/img/logo2.png" class="c-sidebar-brand-minimized" size="custom-size" :height="35" />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="itemsNav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
//import nav from './_nav'

import cons from '@/const'
import axios from "axios"

export default {
  name: 'TheSidebar',
  //nav,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  },
  data () {
    return {
      
      //tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,

      itemsNav: [],
    }
  },
  beforeMount() {
    this.getModulos()
  },
  methods: {
    getModulos: function(){
      this.itemsNav = [
          {
            _name: 'CSidebarNav',
            _children: [
                {
                    "_name": "CSidebarNavDropdown",
                    "icon": "cil-settings",
                    "route": '/admin/administracion',
                    "name": "Administracion",
                    "items": [
                        {
                            "icon": "cil-people",
                            "name": "Clientes",
                            "to": "/admin/administracion/clientes"
                        },
                    ] 
                }
            ]
          }
        ]
      /*var url = cons.port+"://" + cons.ipServer + "/get_modulos/";
      axios({
            method: "GET", 
            "url": url,
            "headers": {
                'Authorization': `${this.tokenLogin}`
            }
      }).then(result => {

        this.itemsNav = [
          {
            _name: 'CSidebarNav',
            _children: result.data
          }
        ]
        
      }, error => {
            let mensaje = `Problema al obtener modulos, ${error}`
      })
      .finally(() => {
      });*/
      
        
    },
  }
}
</script>

<style scoped>
.c-sidebar {
    color: #fff;
    background: #7E1146;
}
.c-sidebar .c-sidebar-brand {
    color: #fff;
    background: #fff
}
.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: rgb(156,21,81, 0.2)
}
@media (hover: hover), not all{
  .c-sidebar .c-sidebar-nav-link{
      background: #9C1551 !important;
  }
  .c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle {
      
      background: #9C1551 !important;
  }
}

</style>