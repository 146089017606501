<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <!--<CIcon name="logo" height="48" alt="Logo"/>-->
      
      <img src="/img/LOGO-250X76.png" height="48" alt="Logo" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <!--<CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          Users
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          Settings
        </CHeaderNavLink>
      </CHeaderNavItem>-->
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <!--
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink>
      </CHeaderNavItem>-->

      <!--<CButton
        variant="ghost"
        size="sm"
        color="success"
        @click="actualizar"
      >
        <CIcon size="sm" :content="$options.freeSet.cilLoopCircular" :class="classActualizar" /> Actualizar
      </CButton>-->

      <!--<Notificaciones />

      <TheHeaderDropdownAccnt/>-->
    </CHeaderNav>
    <!--<CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>-->
  </CHeader>
</template>

<script>
//import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
//import Notificaciones from './Notificaciones'

import { freeSet } from '@coreui/icons'

export default {
  name: 'TheHeaderAdmin',
  freeSet,
  components: {
    //TheHeaderDropdownAccnt,
    //Notificaciones
  },
  data () {
    return {
      classActualizar: ""
    }
  },
  methods:{
    actualizar: function(){
      this.classActualizar = "imgr"
      

    }
  }
}
</script>

<style >
@keyframes rotate {from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}}
@-webkit-keyframes rotate {from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(360deg);}}
.imgr{
    -webkit-animation: 3s rotate linear infinite;
    animation: 3s rotate  linear infinite;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}
</style>